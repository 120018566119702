<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="submitProduct" id="productForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        text="Submit"
        type="submit"
        form="productForm"
        styling="primary"
        validateBeforeSubmit
      ></Button>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

import { productModel, productSchema } from '../../formSchemas/productForm';

const initialData = () => ({
  formErrors: [],
  formModel: productModel,
  formSchema: productSchema,
  title: 'Create new Product',
  options: [],
  kycItems: [],
  selected: '',
  partnerSelected: '',
});

export default {
  name: 'ProductForm',
  emits: ['close-modal'],
  props: ['product', 'display'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return initialData();
  },
  methods: {
    ...mapActions('kycs', ['fetchKycs']),
    ...mapActions('partners', ['fetchPartners']),

    submitProduct() {
      this.formErrors = [];
      const formCopy = { ...this.formModel };

      const data = {};
      data.name = formCopy.name;
      data.loan_limit = formCopy.loan_limit;
      data.transaction_charge = formCopy.transaction_charge;
      data.minimum_interest_period = formCopy.minimum_interest_period;
      data.repayment_model = formCopy.repayment_model;
      data.penalty_rate = formCopy.penalty_rate;
      data.ref = formCopy.ref;
      data.min_interest_rate = formCopy.min_interest_rate;
      data.max_interest_rate = formCopy.max_interest_rate;
      data.default_interest_rate = formCopy.default_interest_rate;
      data.partner = formCopy.partner;
      const { kyc } = formCopy;
      if (kyc) {
        const kycPayload = kyc.map((kycDoc) => {
          const kycData = { code: kycDoc };
          return kycData;
        });
        data.kyc_items = kycPayload;
      }

      const method = formCopy.url ? 'PATCH' : 'POST';
      const ref = formCopy.url ? formCopy.url.split('/').pop() : null;
      this.$store
        .dispatch(`products/createOrEditProduct`, {
          data,
          method,
          ref,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
    async selectKYC() {
      const data = [];
      this.kycs?.results.forEach((option) => {
        const { value } = option;
        data.push({ name: value });
      });
      this.kycItems = data;
    },
    async selectPartner() {
      const data = [];
      this.allPartners.forEach((option) => {
        const { ref, url } = option;
        data.push({ name: ref, partnerUrl: url });
      });
      this.options = data;
    },
    async getDropdownValues() {
      await this.selectKYC();
      await this.selectPartner();
    },
    checkProduct() {
      if (this.product !== null) {
        this.title = `Edit ${this.product.name}`;
        this.formModel = { ...this.product };
      }
    },
    partnerDropdownOptions(options) {
      const data = [];
      options.forEach((option) => {
        const id = option.url;
        const entry = { id, ...option };
        data.push(entry);
      });
      return data;
    },
    kycDropDownOptions(options) {
      const data = [];
      options.results.forEach((option) => {
        const id = option.code;
        const value = option.code;
        const name = option.value;
        const entry = { id, value, name };
        data.push(entry);
      });
      return data;
    },
  },
  watch: {
    product: {
      handler() {
        this.checkProduct();
      },
      deep: true,
    },
    display: {
      handler() {
        if (!this.display) {
          Object.assign(this.$data, initialData());
        }
      },
    },
  },
  async mounted() {
    await this.fetchKycs();
    await this.fetchPartners();
    await this.getDropdownValues();
    this.formSchema = this.schemaWithPartners;
    if (!this.product) this.$emit('close-modal');
    else this.checkProduct();
  },
  computed: {
    ...mapGetters('kycs', ['kycs']),
    ...mapGetters('partners', ['allPartners']),

    schemaWithPartners() {
      const partnerOptionsWithId = this.partnerDropdownOptions(
        this.allPartners,
      );

      const kycOptionsWithId = this.kycDropDownOptions(this.kycs);

      const finalData = {
        fields: [],
      };

      productSchema.fields.forEach((field) => {
        if (field.model === 'partner') {
          const { values, ...rest } = field;
          const newField = { ...rest, values: () => partnerOptionsWithId };
          finalData.fields.push(newField);
          return;
        }
        if (field.model === 'kycs') {
          const { values, ...rest } = field;
          const newField = { ...rest, values: () => kycOptionsWithId };
          finalData.fields.push(newField);
          return;
        }

        finalData.fields.push(field);
      });

      return finalData;
    },
  },
};
</script>
