/* eslint-disable no-param-reassign */
import {
  getFilteredLoans,
  getOutstandingLoanBalance,
  actOnLoan,
  getLoan,
  addExtraCharge,
  postWaiver,
} from '../api/loans.api';
import { formatCurrency } from '../utils/currency';

const currencyKeys = [
  'applied_amount',
  'outstanding_balance',
  'outstanding_charges',
  'outstanding_interest',
  'outstanding_penalty',
  'outstanding_principal',
  'accrued_penalty',
  'accrued_rollover',
  'outstanding_rollover_fee',
  'principal',
  'total_expected_repayment',
];

const state = () => ({
  loans: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
  current_loan: {
    repayments: [],
    loan: [],
    comments: [],
    extra_charges: [],
  },
});

const getters = {
  loans: (state) => state.loans,
  current_loan: (state) => state.current_loan,
  last_loan: (state) => state.last_loan,
};

const actions = {
  async fetchLoan({ commit }, loanRef) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getLoan(loanRef);
      const { data } = response;

      commit('set_current_loan', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch loan. An error happened ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },

  async fetchFilteredLoans({ commit }, { status, page, searchTerm }) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getFilteredLoans(status, page, searchTerm);
      const { data } = response;

      commit('set_loans', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch loans. An error happened ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },

  async addExtraCharge({ commit }, { data, ref }) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      await addExtraCharge(data);
      commit(
        'common/success_snackbar',
        `Extra charge added to loan successfully!`,
        {
          root: true,
        },
      );
      const resp = await getLoan(ref);
      commit('set_current_loan', resp.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed add an extra charge. An error happened ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },

  async postWaiver({ commit }, { data, loanRef }) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      await postWaiver(data);
      commit('common/success_snackbar', `Waiver posted successfully!`, {
        root: true,
      });
      const resp = await getLoan(loanRef);
      commit('set_current_loan', resp.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to post waiver. An error happened ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },

  async requestOutstandingBalance({ commit }, { date, loanRef }) {
    try {
      const resp = await getOutstandingLoanBalance(loanRef, date);
      return resp.data;
    } catch (err) {
      commit(
        'common/error_snackbar',
        'Failed to fetch the outstanding balance. Please try again later.',
        {
          root: true,
        },
      );
      return {};
    }
  },

  async actOnLoan({ commit }, { loanRef, loanAction }) {
    try {
      const resp = await actOnLoan(loanRef, loanAction);
      return resp.data;
    } catch (err) {
      commit(
        'common/error_snackbar',
        `Failed to ${loanAction} loan ${loanRef}. Please try again later.`,
        {
          root: true,
        },
      );
      return {};
    }
  },
};

const mutations = {
  initial_state(state) {
    state.loans = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_loans(state, data) {
    data.results = data.results.map((value) => {
      const loan = { ...value };
      Object.keys(loan).forEach((key) => {
        if (currencyKeys.includes(key)) {
          loan[key] = formatCurrency(loan[key]);
        }
      });
      return loan;
    });
    state.loans = data;
  },
  set_current_loan(state, data) {
    Object.keys(data).forEach((key) => {
      if (currencyKeys.includes(key)) {
        data[key] = formatCurrency(data[key]);
      }
    });

    const repaymentCurrencyFields = [
      'amount',
      'interest_paid',
      'outstanding_balance_after_repayment',
      'principal_paid',
      'rollover_fee_paid',
    ];
    data.repayments.forEach((payment) => {
      Object.keys(payment).forEach((key) => {
        if (repaymentCurrencyFields.includes(key)) {
          payment[key] = formatCurrency(payment[key]);
        }
      });
    });
    state.current_loan = data;
  },
  set_last_loan(state, data) {
    state.last_loan = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
