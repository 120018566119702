const productSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'Name',
      model: 'name',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Loan Limit',
      model: 'loan_limit',
      placeholder: 'Product level loan limit',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Transaction Charge',
      model: 'transaction_charge',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Minimum Interest Period',
      model: 'minimum_interest_period',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'select',
      label: 'Repayment Model',
      model: 'repayment_model',
      values: [
        'fixedRateSingleInstallment',
        'reducingBalanceSingleInstallment',
      ],
      required: true,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Penalty Rate',
      model: 'penalty_rate',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Minimum Interest Rate',
      model: 'min_interest_rate',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Maximum Interest Rate',
      model: 'max_interest_rate',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Default Interest Rate',
      model: 'default_interest_rate',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Product Code',
      model: 'ref',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'select',
      label: 'Select Partner',
      model: 'partner',
      required: true,
      values: () => {
        return {};
      },
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'select',
      label: 'Select KYC document',
      model: 'kycs',
      multi: true,
      required: true,
      multiSelect: true,
      values: () => {
        return {};
      },
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
  ],
};
const productModel = {
  name: '',
  loan_limit: '',
  transaction_charge: '',
  minimum_interest_period: '',
  repayment_model: {},
  penalty_rate: '',
  ref: '',
  min_interest_rate: '',
  max_interest_rate: '',
  default_interest_rate: '',
  partner: {},
  kycs: {},
};

export { productModel, productSchema };
