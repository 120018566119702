import {
  getProducts,
  deleteProduct,
  createOrEditProduct,
} from '../api/products.api';

const state = () => ({
  products: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
  allProducts: {
    results: [],
  },
});

const getters = {
  products: (state) => state.products,
  allProducts: (state) => state.allProducts,
};

const actions = {
  async fetchProducts({ commit }, page) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getProducts(page);
      const products = [];
      // TODO return product code instead of product url
      response.data.results.forEach((product) => {
        const partnerUrlSegments = product.partner.split('/');
        products.push({
          ...product,
          partner: partnerUrlSegments[partnerUrlSegments.length - 1],
          partnerUrl: product.partner,
        });
      });
      response.data.results = products;
      commit('set_products', response.data);
      const allProductsList = [];
      const borrowerPages = response.data.pages;
      for (let i = 1; i <= borrowerPages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const resp = await getProducts(i);
        resp.data.results.forEach((product) => {
          const partnerUrlSegments = product.partner.split('/');
          allProductsList.push({
            ...product,
            partner: partnerUrlSegments[partnerUrlSegments.length - 1],
          });
        });
      }
      commit('set_all_products', allProductsList);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch Products. Please try again later.',
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async createOrEditProduct({ commit }, { data, method, ref }) {
    try {
      const resp = await createOrEditProduct(data, method, ref);
      commit(method === 'POST' ? 'add_product' : 'updated_product', {
        data: resp.data,
        ref,
      });
      commit(
        'common/success_snackbar',
        `Product ${method === 'POST' ? 'created' : 'edited'} successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async deleteProduct({ commit }, { ref }) {
    try {
      await deleteProduct(ref);
      commit('deleted_product', ref);
      commit('common/success_snackbar', 'Product deleted successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.products = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_products(state, data) {
    state.products = data;
  },
  set_all_products(state, data) {
    state.allProducts.results = data;
  },
  add_product(state, { ...data }) {
    state.products.results.unshift(data.data);
  },
  updated_product(state, { ...data }) {
    state.products.results = state.products.results.map((item) => {
      let product = item;
      if (product.ref === data.ref) {
        product = { ...product, ...data.data };
        const partnerUrlSegments = product.partner.split('/');
        product.partner = partnerUrlSegments[partnerUrlSegments.length - 1];
      }
      return product;
    });
  },
  deleted_product(state, ref) {
    const { results } = state.products;
    const item = results.find((product) => product.ref === ref);
    results.splice(results.indexOf(item), 1);
    state.products.results = results;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
