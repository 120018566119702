import {
  getApplications,
  vetApplication,
  createApplication,
  uploadLoanTermsDocument,
} from '../api/applications.api';
import { disburseLoan } from '../api/loans.api';

const state = () => ({
  applications: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
  current_application: {},
});

const getters = {
  applications: (state) => state.applications,
  current_application: (state) => state.current_application,
};

const actions = {
  async fetchApplications({ commit, rootState }, payload = {}) {
    commit('initial_state');
    const { page, searchTerm } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getApplications(page, searchTerm);
      const { data } = response;
      const applications = [];

      data.results.forEach((application) => {
        if (!application.borrower) return;
        const { borrower } = application;
        const partnerUrl = borrower.partner ? borrower.partner.split('/') : '';
        const partner = partnerUrl
          ? rootState.partners.allPartners.find(
              (partnerObject) =>
                partnerObject.ref === partnerUrl[partnerUrl.length - 1],
            )
          : '';
        applications.push({
          ...application,
          borrower: borrower.name || '-',
          docs: borrower.tax_doc_url || '-',
          partnerName: partner ? partner.name.toUpperCase() : '',
        });
      });

      data.results = applications.sort((a, b) =>
        a.status.localeCompare(b.status),
      );

      commit('set_applications', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch applications. Please try again later.',
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },

  async createApplication({ commit }, { data }) {
    try {
      const { borrower, product, ...rest } = data;
      const borrowerURL = `${process.env.VUE_APP_BASE_URL}/api/v2/borrowers/${borrower.id}`;
      const productURL = `${process.env.VUE_APP_BASE_URL}/api/v2/products/${product}`;
      const payload = {
        ...rest,
        borrower: borrowerURL,
        product: productURL,
      };

      const resp = await createApplication(payload);
      const application = resp.data;

      commit('add_application', {
        ...application,
        borrower: borrower.name,
        partnerName: borrower.partner,
      });
      commit(
        'common/success_snackbar',
        `Application ${resp.data.ref} has been created succesfully! `,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async vetApplication({ commit }, { data, application }) {
    try {
      const resp = await vetApplication(application.ref, data);
      const changeData = {
        status: resp.data.status,
        comment: data.comment,
        ref: application.ref,
      };

      const loanTermsData = {
        ref: application.ref,
        fileName: `${application.ref}.pdf`,
      };
      if (data.status === 'approved') {
        await uploadLoanTermsDocument(loanTermsData);
      }
      commit('update_application', changeData);
      commit(
        'common/success_snackbar',
        `Loan application ${resp.data.status}.`.trim(),
        {
          root: true,
        },
      );
      window.location.reload();
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async saveLoanTermsBinary({ commit }, { data }) {
    try {
      commit('update_loanterms_binary', data);
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async disburseLoanAction({ commit }, { data, ref }) {
    try {
      const { date, vsmTillNumber } = data;

      const refinedData = {
        disbursal_code: data.disbursalCode,
        approved_amount: data.approvedAmount,
        disbursement_date: date,
        disbursement_account: vsmTillNumber,
      };

      const payload = { ref, data: refinedData };
      const resp = await disburseLoan(payload);

      const changeData = { ref, status: resp.data.status };
      commit('update_application', changeData);
      commit(
        'common/success_snackbar',
        `${resp.data.principal.toLocaleString()} has been disbursed for ` +
          `application ${resp.data.ref} on ${resp.data.disbursement_date}`,
        {
          root: true,
        },
      );
      return resp.data;
    } catch (errors) {
      commit(
        'common/error_snackbar',
        `Failed to disburse loan. Please try again later. \n ${errors}`,
        {
          root: true,
        },
      );
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async saveCurrentLoanApplication({ commit }, { data }) {
    try {
      commit('update_current_application', data);
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.applications = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  add_application(state, data) {
    state.applications.results.push(data);
    state.applications.count += 1;
  },
  set_applications(state, data) {
    data.results.map((loanApp) => {
      const params = loanApp.product.split('/');
      // eslint-disable-next-line no-param-reassign
      loanApp.partnerName = params[params.length - 1];
      return loanApp;
    });
    state.applications = data;
  },
  update_application(state, { ref, ...data }) {
    state.applications.results = state.applications.results
      .map((loanApp) => {
        if (loanApp.ref === ref) {
          // eslint-disable-next-line no-param-reassign
          loanApp = { ...loanApp, ...data };
        }
        return loanApp;
      })
      .filter((loanApp) => !['active', 'rejected'].includes(loanApp.status));
  },
  update_current_application(state, data) {
    state.current_application = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
